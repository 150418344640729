<script lang="ts">
	import RouteMap from "./route-map.svelte";
	import SvgIcon from "./svg-icon.svelte";
	import { FluentProvider, Localized, Overlay } from "@nubolab-ffwd/svelte-fluent";
	import { bundles, localize, language } from "../localization";

	export let routeDataSelector: string;
	export let poiDataSelector: string;
	export let accesstoken: string;
	export let zoom: number;
	export let bearing: number;
	export let pitch: number;

	let showOverlay: boolean = true;
	let toggleInteractive: () => void;

	function onInteractiveChanged(event: CustomEvent<{ isInteractive: boolean }>) {
		showOverlay = !event.detail.isInteractive;
	}
</script>

<FluentProvider {bundles}>
	<div>
		<RouteMap {routeDataSelector} {poiDataSelector} {accesstoken} {zoom} {bearing} {pitch} zoomToExtents={true} offset={[0, 70]} hideInteractiveToggle={$$slots.default} bind:toggleInteractive on:interactivechanged={onInteractiveChanged} />
		{#if $$slots.default}
			<div class="map-block__overlay" class:is-visible={showOverlay}>
				<p class="map-block__overlay-text">
					<slot />
					<button class="button is-outlined is-responsive button-activate" on:click={toggleInteractive}>
						<SvgIcon name="press-button" />
						<span><Localized id="map-activate-long" /></span>
					</button>
				</p>
			</div>
		{/if}
	</div>
</FluentProvider>

<style lang="scss">
    .button-activate {
        vertical-align: middle;
    }
</style>
