<script lang="ts">
	import { Tabs } from "bits-ui";
	import { onMount, tick } from "svelte";

	interface TabDefinition {
		title: string;
		id: string;
		selector: string;
	}

	export let dataSelector: string;
	let tabs: TabDefinition[] = [];
	let tabContentElements: HTMLElement[] = [];

	onMount(async () => {
		let dataElement = document.querySelector<HTMLElement>(dataSelector);
		tabs = JSON.parse(dataElement?.innerText ?? "[]");

		for (let tab of tabs) {
			let element = document.querySelector<HTMLElement>(tab.selector);
			if (element) {
				tabContentElements.push(element);
			}
		}
	});
</script>

<Tabs.Root>
	<Tabs.List asChild let:builder={listBuilder}>
		<div class="tabs is-toggle mb-0">
			<ul use:listBuilder.action {...listBuilder}>
				{#each tabs as tab}
					<Tabs.Trigger value={tab.id} asChild let:builder>
						<li class:is-active={builder["data-state"] === "active"} use:builder.action {...builder}>
							<a>{tab.title}</a>
						</li>
					</Tabs.Trigger>
				{/each}
			</ul>
		</div>
	</Tabs.List>
	{#each tabs as tab, index}
		<Tabs.Content class="tabs-content" value={tab.id}>
			{@html tabContentElements[index].outerHTML}
		</Tabs.Content>
	{/each}
</Tabs.Root>

<div class="is-hidden">
	<slot />
</div>
