<script lang="ts">
	import type { FeatureCollection } from "geojson";
	import { createEventDispatcher, onMount } from "svelte";
	import { fade } from "svelte/transition";
	import { centroid } from "@turf/centroid";
	import { bbox } from "@turf/bbox";
	import { Circle } from "svelte-loading-spinners";
	import SvgIcon from "./svg-icon.svelte";
	import type { LngLatBoundsLike } from "mapbox-gl";
	import { FluentProvider, Localized, Overlay } from "@nubolab-ffwd/svelte-fluent";
	import { bundles, localize, language } from "../localization";

	const EmptyFeatureCollection: FeatureCollection = { type: "FeatureCollection", features: [] };

	export let routeDataSelector: string;
	export let poiDataSelector: string;
	export let accesstoken: string;
	export let zoom: number;
	export let bearing: number;
	export let pitch: number;
	export let offset: [number, number] = [0, 0];
	export let zoomToExtents: boolean = false;
	export let hideInteractiveToggle: boolean = false;

	let routeFeatures: FeatureCollection = EmptyFeatureCollection;
	let poiFeatures: FeatureCollection = EmptyFeatureCollection;
	let mapElement: HTMLElement;
	let map: mapboxgl.Map;
	let isInteractive: boolean = false;
	let isDirty: boolean = false;
	let isLoading: boolean = true;
	let dispatcher = createEventDispatcher();

	// if we're used as a custom element, we need to convert the boolean attribute to a boolean value
	if (typeof zoomToExtents === "string") {
		zoomToExtents = (zoomToExtents as any) === "";
	}

	onMount(() => {
		let routeDataElement = document.querySelector<HTMLElement>(routeDataSelector);
		if (routeDataElement?.innerText != null && routeDataElement?.innerText.length > 0) {
			try {
				routeFeatures = JSON.parse(routeDataElement.innerText);
			} catch (error) {
				console.error("Error parsing route data", error);
			}
		}

		let poiDataElement = document.querySelector<HTMLElement>(poiDataSelector);
		if (poiDataElement?.innerText != null && poiDataElement?.innerText.length > 0) {
			try {
				poiFeatures = JSON.parse(poiDataElement.innerText);
			} catch (error) {
				console.error("Error parsing poi data", error);
			}
		}

		loadMapbox();
	});

	async function loadMapbox() {
		let mapboxgl = (await import("mapbox-gl")).default;
		isLoading = false;

		mapboxgl.accessToken = accesstoken;
		map = new mapboxgl.Map({
			container: mapElement,
			style: "mapbox://styles/wagnergraphics/cllm5hzy401ft01pbfv5b66zo",
			zoom: zoom,
			bearing: bearing,
			pitch: pitch,
		});
		map.addControl(new mapboxgl.NavigationControl());
		map.addControl(new mapboxgl.FullscreenControl());

		map.on("style.load", () => {
			map.addSource("route", {
				type: "geojson",
				data: routeFeatures,
			});
			map.addLayer({
				id: "route",
				type: "line",
				source: "route",
				layout: {
					"line-join": "round",
					"line-cap": "round",
				},
				paint: {
					"line-color": "#FE9E00",
					"line-width": 3,
				},
			});

			map.addSource("poi", {
				type: "geojson",
				data: poiFeatures,
			});
			map.addLayer({
				id: "poi",
				type: "symbol",
				source: "poi",
				layout: {
					"icon-image": ["get", "icon"],
					"icon-size": 0.75,
					"icon-anchor": "bottom",
					"text-field": ["get", "name"],
					"text-offset": [0, 0.333],
					"text-anchor": "top",
					"text-size": 18,
				},
				paint: {
					"text-color": "#FFF",
					"text-halo-color": "#000",
					"text-halo-width": 1,
				},
			});

			if (routeFeatures.features.length > 0) {
				map.setCenter(centroid(routeFeatures).geometry.coordinates as [number, number]);
			} else if (poiFeatures.features.length > 0) {
				map.setCenter(centroid(poiFeatures).geometry.coordinates as [number, number]);
			}

			if (zoomToExtents) {
				map.fitBounds(bbox(routeFeatures) as LngLatBoundsLike, {
					animate: false,
					padding: 10,
					pitch: pitch,
					bearing: bearing,
					offset: offset,
				});
			}

			map.once("move", () => {
				isDirty = true;
			});
			map.once("rotate", () => {
				isDirty = true;
			});
			map.once("pitch", () => {
				isDirty = true;
			});
			map.once("zoom", () => {
				isDirty = true;
			});

			map.scrollZoom.disable();
			map.dragPan.disable();
			map.touchZoomRotate.disable();
			map.doubleClickZoom.disable();
		});
	}

	export function toggleInteractive() {
		if (isInteractive) {
			map.scrollZoom.disable();
			map.dragPan.disable();
			map.touchZoomRotate.disable();
			map.doubleClickZoom.disable();
		} else {
			map.scrollZoom.enable();
			map.dragPan.enable();
			map.touchZoomRotate.enable();
			map.doubleClickZoom.enable();
		}

		console.log(isDirty);
		if (!isDirty && zoomToExtents) {
			map.fitBounds(bbox(routeFeatures) as LngLatBoundsLike, {
				speed: 0.25,
				padding: 10,
				pitch: pitch,
				bearing: bearing,
			});
		}

		isInteractive = !isInteractive;
		dispatcher("interactivechanged", { isInteractive });
	}
</script>

<FluentProvider {bundles}>
	<div class="route-map">
		{#if isInteractive}
			<button class="button is-ghost has-text-white is-responsive route-map__lock" on:click={() => toggleInteractive()} transition:fade={{ duration: 200 }}>
				<SvgIcon name="padlock-square-1" />
				<span><Localized id="map-deactivate" /></span>
			</button>
		{:else if !hideInteractiveToggle}
			<div class="route-map__overlay is-overlay" transition:fade={{ duration: 200 }}>
				<button class="button has-label is-large is-text is-inverted is-responsive" on:click={() => toggleInteractive()}>
					<SvgIcon name="press-button" />
					<span><Localized id="map-activate" /></span>
				</button>
			</div>
		{/if}
		<div class="route-map__map" bind:this={mapElement} />

		{#if isLoading}
			<div class="route-map__loader" transition:fade>
				<Circle color="#002386" />
				<Localized id="map-loading" />
			</div>
		{/if}
	</div>
</FluentProvider>

<style lang="scss">
	@import "mapbox-gl/dist/mapbox-gl.css";

	.route-map {
		position: relative;
	}

	.route-map__overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transition: opacity ease-out-sine 200ms;
		z-index: 1;

		&:hover {
			opacity: 1;
		}
	}

	.route-map__lock {
		position: absolute;
		right: 1em;
		bottom: 1em;
		z-index: 2;
	}

	.route-map__loader {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}
</style>
