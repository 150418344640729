import "../Styles/index.scss";

import svelteRetag from "svelte-retag";

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

import Flickity from "flickity";
for (let element of document.querySelectorAll(".gallery-block__flickity")) {
	new Flickity(element, {
		pageDots: false,
		wrapAround: true,
	});
}

import BulmaTabs from "./components/bulma-tabs.svelte";
svelteRetag({
	component: BulmaTabs,
	tagname: "bulma-tabs",
});

import RouteMap from "./components/route-map.svelte";
svelteRetag({
	component: RouteMap,
	tagname: "route-map",
});

import OverviewMap from "./components/overview-map.svelte";
svelteRetag({
	component: OverviewMap,
	tagname: "overview-map",
});

async function loadCustomElements() {
	if (document.querySelector("custom-form") != null) {
		let module = await import("./custom-form/custom-form.svelte");
		svelteRetag({
			component: module.default,
			tagname: "custom-form",
		});
	}

	if (document.querySelector("ticket-shop") != null) {
		let module = await import("./ticket-shop/ticket-shop.svelte");
		svelteRetag({
			component: module.default,
			tagname: "ticket-shop",
		});
	}
}
loadCustomElements();

document.querySelector("#navbarMenuToggle")?.addEventListener("click", () => {
	document.documentElement.classList.add("is-nav-open");
});
document.querySelector("#navOverlayClose")?.addEventListener("click", () => {
	document.documentElement.classList.remove("is-nav-open");
});

let navbarHints = document.querySelector(".navbar-hints");
if (navbarHints != null) {
	let trigger = navbarHints.querySelector(".navbar-hints__toggle");
	trigger?.addEventListener("click", e => {
		if (navbarHints.classList.contains("is-active")) {
			return;
		}

		let close = (e2: MouseEvent) => {
			let target = e2.target as Node
			if (navbarHints.contains(target) && !trigger.contains(target)) {
				return;
			}

			navbarHints.classList.remove("is-active");
			document.removeEventListener("click", close);
		};
		document.addEventListener("click", close);
		navbarHints.classList.add("is-active");
		e.stopPropagation();
	});

}
